/**
 * For legacy UI reasons the mobile apps enforce a hard 13 character limit on
 * names. To retain compatibility we need to keep this same limitation until the
 * legacy code is removed from the mobile apps
 */
export const MAX_NAME_LENGTH = 13
/**
 * This flag is set on a Stripe product when it should available for users to subscribe to.
 */
export const AVAILABLE_FOR_WEB_SUBSCRIPTIONS_FLAG = 'available_for_web_subscriptions'
/**
 * Specifies that the customer is used as the single reference point for the
 * basic pricing which we show to users before we have enough information about
 * them to create a fully fledged Stripe customer.
 */
export const STRIPE_BASE_PRICING_CUSTOMER_FLAG = 'use_for_web_subscriptions_base_pricing'
/**
 * Metadata key used on coupons to specify if we should skip upfront payment
 * when the coupon is applied. Only relevant if the coupon also has a positive
 * number of free trial days associated with it. The customer will be required
 * to add payment details to their Stripe account before the end of the trial
 * period to continue accessing the app.
 *
 * Expects the value to be `true`.
 */
export const STRIPE_COUPON_SKIP_PAYMENT_IF_TRIAL_FLAG = 'skip_payment_if_trial'
/**
 * Metadata key used on both coupons and prices in Stripe to specify how many
 * days a free trial should last.
 *
 * @see https://www.notion.so/thebodycoach/Web-Subscriptions-Free-Trial-3d28492f48da434e98dc16d0f64347f5
 */
export const STRIPE_FREE_TRIAL_DAYS_KEY = 'free_trial_days'
/**
 * Metadata key that indicates that a coupon is for a B2B customer. This will
 * trigger the removal of the payment step and relevant copy tweaks across the
 * checkout flow
 */
export const STRIPE_COUPON_IS_B2B_KEY = 'is_b2b'
/**
 * Metadata key used to provide a descriptive name for a coupon that can be
 * shown to users in pieces of string such as "[My coupon name] discount". For
 * B2B customers this should be set to the name of the company.
 */
export const STRIPE_COUPON_USER_FACING_NAME_KEY = 'user_facing_name'
/**
 * URL to use in the case that the user wants to submit a support request
 */
export const SUPPORT_URL = 'https://thebodycoach.zendesk.com/hc/en-gb/requests/new'
/**
 * URL to use when the app should be directly opened
 */
export const OPEN_APP_URL = 'https://64hb.adj.st/?adj_t=10dadm7e&adj_campaign=Web-Sub-Success'
